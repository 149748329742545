<script>
  import { GridColumn, Separator, Icon, WidgetHeader, WidgetFooter } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import Section from './Section'
  import css from './style.scss'

  export let config

  const cN = classNames.bind(css)

  const {
    withTopMargin = true,
    withBottomMargin = true,
    titleTag,
    title,
    description,
    sections,
    priceFormat,
    showOnMobile = 2,
    showOnDesktop = 4,
  } = config
</script>

{@html Icon.spriteContent}

<div class={cN('price-list')} id='price-list'>
  <WidgetHeader {title} {titleTag} subTitle={description} withMargin={withTopMargin} />

  {#each sections as section, sectionIdx}
    <Section {...section} {priceFormat} {showOnMobile} {showOnDesktop} />

    {#if sectionIdx < sections.length - 1}
      <GridColumn>
        <Separator key={50} size={Separator.SIZES.SMALL} />
      </GridColumn>
    {/if}
  {/each}

  <WidgetFooter withMargin={withBottomMargin} />
</div>
