<script>
  import {
    ActionText,
    ButtonTertiary,
    Icon,
    Separator,
    Text,
    GridColumn,
  } from '@qlean/ui-kit-web-svelte'
  import { Bus, TOOLTIP_SHOW_REQUEST } from '@qlean/web-core'
  import classNames from 'classnames/bind'

  import css from '../style.scss'

  export let options
  export let title
  export let isWide
  export let priceFormat
  export let isHidden
  export let showOnMobile
  export let showOnDesktop
  export let toggleVisibility
  export let pluralizeOptions
  export let isAddition

  const cN = classNames.bind(css)

  function getDescContainerId(id) {
    return `description_${id}`
  }

  function showTooltip(data) {
    if (data === undefined) {
      return
    }

    const { id } = data
    const descriptionContainer = document.getElementById(getDescContainerId(id))

    if (descriptionContainer === null) {
      return
    }

    const text = descriptionContainer.innerText

    Bus.emit(TOOLTIP_SHOW_REQUEST, { text, target: document.getElementById(id) })
  }

  const mobileHiddenAmount = options.length - showOnMobile

  function hasDescription(option) {
    return option.shortDesc !== null && option.shortDesc.trim() !== ''
  }
</script>

<GridColumn
  widthMiddle={isWide ? 8 : 4}
  class={cN($$props.class, 'price-list__options-group', {
    'price-list__options-group--wide': isWide,
  })}>
  {#if title !== undefined}
    <div
      class={cN('price-list__options-group-title', {
        'price-list__options-group-title--addition': isAddition,
      })}>
      <Separator key={10} size={Separator.SIZES.SMALL} />
      <ActionText>{title}</ActionText>
      <Separator key={15} size={Separator.SIZES.SMALLEST} />
    </div>
  {/if}
  {#each options as option, rowIdx}
    <GridColumn
      widthMiddle={4}
      class={cN('price-list__option', {
        'price-list__option--addition': isAddition,
        'price-list__option--hidden-on-mobile': isHidden && rowIdx >= showOnMobile,
        'price-list__option--hidden-on-not-mobile':
          isHidden && rowIdx >= (isWide ? showOnDesktop * 2 : showOnDesktop),
        'price-list__option--last-visible-on-mobile':
          (isHidden && rowIdx + 1 === showOnMobile) || rowIdx === options.length - 1,
      })}>
      <Separator key={15} size={Separator.SIZES.SMALLEST} />
      <div
        class={cN('price-list__option-content', {
          'price-list__option-content--clickable': hasDescription(option),
        })}
        on:click={() => showTooltip(option)}>
        <div class={cN('price-list__option-content-text')}>
          <Text class={cN('price-list__option-title')}>{option.title}</Text>
          {#if option.price !== undefined}
            <Text class={cN('price-list__option-price')}>
              <!-- @todo: move to data preparation -->
              {(option.format || priceFormat).replace('$1', option.price)}
            </Text>
          {/if}
        </div>
        {#if hasDescription(option)}
          <Icon
            key={Icon.KEYS.MEDIUM}
            name={Icon.NAMES.question}
            class={cN('price-list__offer-description-icon')}
            id={option.id} />
          <span id={getDescContainerId(option.id)} class={cN('price-list__offer-description')}>
            {option.shortDesc}
          </span>
        {/if}
      </div>
      <Separator key={15} size={Separator.SIZES.SMALLEST} />
    </GridColumn>
  {/each}
  {#if mobileHiddenAmount > 0}
    <GridColumn widthMiddle={4}>
      <ButtonTertiary
        class={cN('price-list__button')}
        width={ButtonTertiary.WIDTHS.WIDE}
        rounded
        on:click={toggleVisibility}>
        {#if isHidden}
          Ещё {mobileHiddenAmount} {pluralizeOptions(mobileHiddenAmount)}
        {:else}Свернуть{/if}
      </ButtonTertiary>
    </GridColumn>
  {/if}
</GridColumn>
