<script>
  import {
    GridColumn,
    GridContainer,
    Separator,
    Icon,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import Section from './Section'
  import css from './style.scss'

  export let config

  const cN = classNames.bind(css)

  const {
    withTopMargin = true,
    withBottomMargin = true,
    titleTag,
    title,
    description,
    sections,
    priceFormat,
  } = config
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} subTitle={description} withMargin={withTopMargin} />

<GridContainer>
  {#each sections as chunks}
    <GridColumn widthMiddle={4}>
      {#each chunks as section}
        <GridColumn widthMiddle={4}>
          <Section {...section} {cN} {priceFormat} />
          <Separator key={20} size={Separator.SIZES.SMALL} />
        </GridColumn>
      {/each}
    </GridColumn>
  {/each}
</GridContainer>

<WidgetFooter withMargin={withBottomMargin} />
