<script>
  import Reglament from './Reglament'
  import ReglamentAccordion from './ReglamentAccordion'

  export let isAccordion
</script>

{#if isAccordion}
  <ReglamentAccordion {...$$restProps} />
{:else}
  <Reglament {...$$restProps} />
{/if}
