<script>
  import { Text } from '@qlean/ui-kit-web-svelte'

  export let cN
  export let isFirst
  export let title
  export let shortDesc = ''
  export let price
  export let priceFormat
  export let unit = ''
  export let itemPriceFormat

  const priceString = (itemPriceFormat || priceFormat).replace('$1', price)

</script>

<div class={$$props.class}>
  <div class={cN('accordion__option-content', { 'accordion__option-content_first': isFirst })}>
    <div class={cN('accordion__option-describe')}>
      <div>
        <Text>{title}</Text>
      </div>
      <div>
        <Text class={cN('accordion__option-describe-decription')}>{shortDesc}</Text>
      </div>
    </div>
    <div class={cN('accordion__option-price')}>
      <div class={cN('accordion__option-price-text')}>
        <Text>{priceString}</Text>
      </div>
      <div class={cN('accordion__option-price-unit')}>
        <Text>{unit}</Text>
      </div>
    </div>
  </div>
</div>
