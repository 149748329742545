<script>
  import { SubHeader, Icon } from '@qlean/ui-kit-web-svelte'

  import Options from '../Options'

  export let title
  export let iconName
  export let list
  export let isExpanded
  export let priceFormat
  export let cN

  const toggleIsExpanded = () => {
    isExpanded = !isExpanded
  }
</script>

<div class={cN('accordion')}>
  <div
    on:click={toggleIsExpanded}
    class={cN('accordion__section', { accordion__section_expanded: isExpanded })}>
    <Icon name={Icon.NAMES[iconName]} key={Icon.KEYS.LARGE} />
    <div class={cN('accordion__section-title')}>
      <SubHeader size={SubHeader.SIZES.STRONG}>{title}</SubHeader>
    </div>
    <Icon
      name={Icon.NAMES.arrow}
      key={Icon.KEYS.MEDIUM}
      class={cN('accordion__section-icon-arrow', {
        'accordion__section-icon-arrow_active': isExpanded,
      })} />
  </div>
  {#each list as item, key}
    <Options
      {...item}
      {cN}
      {priceFormat}
      isFirst={!key}
      class={cN('accordion__option', { accordion__option_visible: isExpanded })} />
  {/each}
</div>
