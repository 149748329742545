<script>
  import {
    ButtonTertiary,
    GridContainer,
    GridColumn,
    Header,
    Picture,
  } from '@qlean/ui-kit-web-svelte'
  import { pluralize } from '@qlean/web-core'
  import classNames from 'classnames/bind'

  import css from '../style.scss'
  import Options from '../Options'

  export let image
  export let title
  export let priceFormat
  export let included
  export let additions
  export let showOnMobile
  export let showOnDesktop

  const cN = classNames.bind(css)
  const pluralizeOptions = pluralize.bind(null, ['опция', 'опции', 'опций'])

  let hiddenAmountDesktop =
    Math.max(0, included.list.length - showOnDesktop) +
    Math.max(0, additions.list.length - showOnDesktop)
  if (additions.list.length === 0) {
    hiddenAmountDesktop = included.list.length - showOnDesktop * 2
  }
  if (included.list.length === 0) {
    hiddenAmountDesktop = additions.list.length - showOnDesktop * 2
  }

  let isIncludedHidden = true
  let isAdditionsHidden = true

  const toggleIncludedVisibility = () => {
    isIncludedHidden = !isIncludedHidden
  }
  const toggleAdditionsVisibility = () => {
    isAdditionsHidden = !isAdditionsHidden
  }
  const toggleBothVisibility = () => {
    toggleIncludedVisibility()
    toggleAdditionsVisibility()
  }
</script>

<GridContainer class={cN('price-list__section')}>
  <GridColumn widthMiddle={3} class={cN('price-list__section-info')}>
    <div class={cN('price-list__section-title')}>
      {#if image !== undefined}
        <Picture
          {...image}
          type={Picture.PROPORTIONS.EXTRA_WIDE}
          class={cN('price-list__picture')} />
      {/if}
      <div class={cN('price-list__header')}>
        <Header size={Header.SIZES.LIGHT}>{title}</Header>
      </div>
    </div>
    {#if hiddenAmountDesktop > 0}
      <ButtonTertiary
        class={cN('price-list__button')}
        width={ButtonTertiary.WIDTHS.WIDE}
        rounded
        on:click={toggleBothVisibility}>
        {#if isIncludedHidden && isAdditionsHidden}
          Ещё {hiddenAmountDesktop} {pluralizeOptions(hiddenAmountDesktop)}
        {:else}Свернуть{/if}
      </ButtonTertiary>
    {/if}
  </GridColumn>
  <GridColumn width={0} widthMiddle={1} />
  {#if included.list.length > 0}
    <Options
      {showOnMobile}
      {showOnDesktop}
      {pluralizeOptions}
      options={included.list}
      title={included.title}
      isWide={additions.list.length === 0}
      isHidden={isIncludedHidden}
      toggleVisibility={toggleIncludedVisibility}
      class={cN({ 'price-list__options-group--last-in-section': additions.list.length === 0 })} />
  {/if}
  {#if additions.list.length > 0}
    <Options
      {priceFormat}
      {showOnMobile}
      {showOnDesktop}
      {pluralizeOptions}
      options={additions.list}
      title={additions.title}
      isAddition
      isWide={included.list.length === 0}
      isHidden={isAdditionsHidden}
      toggleVisibility={toggleAdditionsVisibility}
      class={cN('price-list__options-group--last-in-section')} />
  {/if}
</GridContainer>
